import { useSelector } from "react-redux";
import { AdminSignup } from "../components/adminSignup/adminSignup";
import { DashboardCard } from "../components/dashboardCard/dashboardCard";
import { DahsboardTable } from "../components/dashboardTable/dahsboardTable";
import { Footer } from "../components/footer/footer";
import { Header } from "../components/header/header";

import useFetch from "../utils/useFetch";
import "./dashboard.css";
export const Dashboard = () => {
  const { role } = useSelector((state) => state.auth);

  const { data, loading, error } = useFetch(
    "https://videoapp.customalloy.com/upload/videos",
    true
  );
  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;
  return (
    <div className="dashboard">
      <Header />
      <DashboardCard role={role} />
      {role && role === "user" ? (
        <DahsboardTable data={data} />
      ) : (
        <AdminSignup />
      )}

      <Footer />
    </div>
  );
};

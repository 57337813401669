import { useSelector } from "react-redux";
import cassWord from "../../images/footerCass.png";
import { ProfileDropdown } from "../profileDropdown/profileDropdown";
export const Header = () => {
  const { role } = useSelector((state) => state.auth);

  return (
    <>
      <div className="header">
        <div className="header__logo ">
          <img
            src={cassWord}
            alt="cass logo"
            style={{ height: "40px", marginLeft: "5px" }}
          />
        </div>
        <div className="header__title ">
          <img
            src="https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
            style={{ width: "40px", height: "40px" }}
          />
          <p className="mx-2 my-2">
            <ProfileDropdown role={role} />
          </p>
        </div>
      </div>
    </>
  );
};

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import "./usersTable.css";
import { useEffect, useState } from "react";
import { Pagination } from "../pagination/pagination";

const LOCAL_SERVER = "http://localhost:8000";
const SERVER = "https://videoapp.customalloy.com";
export const UsersTable = ({ users }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(5);
  const [data, setData] = useState();
  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const currentUsers = data?.slice(firstUserIndex, lastUserIndex);
  console.log(currentUsers);
  useEffect(() => {
    setData(users);
  }, [users, currentPage]);

  return (
    <div>
      <Paper sx={{ width: "90%", marginLeft: "5%" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 850 }}
            aria-label="simple table"
            align="center"
          >
            <TableHead>
              <TableRow className="bg-dark">
                <TableCell className="text-white" align="center">
                  #
                </TableCell>
                <TableCell className="text-white" align="center">
                  User Email
                </TableCell>
                <TableCell className="text-white" align="center">
                  User Created at
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentUsers &&
                currentUsers.map((user, index) => (
                  <TableRow key={user._id}>
                    <TableCell component="th" scope="row" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">
                      {new Date(user.createdAt).toLocaleDateString("en-gb")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="table__pagination">
          {" "}
          <Pagination
            totalPosts={data?.length}
            postsPerPage={usersPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </Paper>
    </div>
  );
};

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const useFetch = (url, sendRequest) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userToken } = useSelector((state) => state.auth);

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${userToken}`,
    };
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url, { headers });
        setData(response.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, userToken]);

  return { data, loading, error, setData };
};

export default useFetch;

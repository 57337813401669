import { Background } from "../UI/background/background";
import "./emailConfirmation.css";
export const EmailConfirmation = () => {
  return (
    <Background>
      {" "}
      <div className="emailConfirmation">
        Check your email to reset password please
      </div>
    </Background>
  );
};

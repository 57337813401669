import "./dashboardCard.css";
import Box from "@mui/material/Box";
export const DashboardCard = () => {
  return (
    <Box
      style={{
        width: "30vw",
        marginTop: "10px",
        textAlign: "center",
      }}
    >
      <b>Video Management Dashboard</b>
    </Box>
  );
};

import "./loader.css";
export const Loader = ({ loadingPercentage }) => {
  return (
    <div className="loader">
      <div>uploading</div>
      <div className="progress col-10 ">
        <div
          className="progress-bar bg-success text-white"
          role="progressbar"
          style={{ width: `${loadingPercentage}%` }}
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {loadingPercentage} %
        </div>
      </div>
    </div>
  );
};

import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import "./signupForm.css";
export const SignupForm = ({ handleClose, setData, users }) => {
  const { userToken } = useSelector((state) => state.auth);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");

  const [message, setMessage] = useState("");
  const LOCAL_URL = "http://localhost:8000";
  const URL = "https://videoapp.customalloy.com";
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const signupHandler = async () => {
    await axios
      .post(
        `${URL}/register`,
        {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          role: role,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then((response) => {
        axios
          .get("https://videoapp.customalloy.com", { headers })
          .then((response) => {
            setData(response.data.data);
          })
          .catch((error) => console.log(error));
        setMessage("You created User Succefully !");

        setTimeout(() => {
          handleClose();
        }, "2000");
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      });
  };

  return (
    <div className="login__form">
      <div className="login__form__div">
        <div>
          <b style={{ fontSize: "20px", color: "rgb(53, 117, 212)" }}>
            CASS-STREAM
          </b>
        </div>
        <div>
          <b style={{ fontSize: "20px", color: "rgb(61, 68, 71)" }}>
            Sign up user
          </b>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="First Name"
          onChange={(e) => {
            setMessage("");
            setFirstName(e.target.value);
          }}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Last Name"
          onChange={(e) => {
            setMessage("");
            setLastName(e.target.value);
          }}
        />
        <input
          type="text"
          className="form-control"
          placeholder="Email"
          onChange={(e) => {
            setMessage("");
            setEmail(e.target.value);
          }}
        />
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          onChange={(e) => {
            setMessage("");
            setPassword(e.target.value);
          }}
        />
        <button className="btn btn-dark" onClick={signupHandler}>
          Sign up user
        </button>
        {message ? <p className="text-center text-danger">{message}</p> : ""}
      </div>
    </div>
  );
};

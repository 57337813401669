import { LoginForm } from "../components/loginForm/loginForm";
import "./login.css";
export const Login = () => {
  return (
    <div className="login__div">
      <div className="login__img__left">
        <p
          style={{
            marginTop: "200px",
            // marginLeft: "100px",
            // position: "absolute",
          }}
        >
          <b>Welcome To CASS INC Stream</b>
        </p>
      </div>
      <div className="login__img__right">
        <LoginForm />
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./redirect.css";
export const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const startTimeout = async () => {
      setInterval(() => {
        navigate("/");
      }, 15000);
    };
    startTimeout();
  }, []);
  const redirectHandler = () => {
    navigate("/");
  };
  return (
    <div className="redirect">
      <div className="redirect__div">
        Password Reset Successful:
        <br /> Your password has been reset. You will be redirected to the login
        page in 30 seconds.
        <br /> If you are not redirected please click this link to go to
        <a className="redirect__a" onClick={redirectHandler}>
          <b></b> login page
        </a>
      </div>
    </div>
  );
};

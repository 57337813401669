import { useState } from "react";
import "./deleteModal.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const LOCAL_SERVER = "http://localhost:8000";
const SERVER = "https://videoapp.customalloy.com";
export const DeleteModal = ({ videoId, videoName, setVideos }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const cancelDeleteHandler = () => {
    setOpen(false);
  };

  const { userToken } = useSelector((state) => state.auth);
  const headers = {
    Authorization: `Bearer ${userToken}`,
  };
  const confirmDeleteHandler = async (e) => {
    e.preventDefault();
    await axios
      .delete(`${SERVER}/upload/videos`, {
        data: { videoId: videoId, videoName: videoName },
        headers: headers,
      })
      .then((response) => {
        axios
          .get(`${SERVER}/upload/videos`, { headers })
          .then((response) => {
            setVideos(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    handleClose();
  };

  return (
    <div>
      <button className="delete__confirm" onClick={handleOpen}>
        Delete Video
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="text-danger"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            This is a delete action!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This video will be permanently deleted and cannot be recovered!
          </Typography>
          <div className="delete__div">
            <button className="delete__cancel" onClick={cancelDeleteHandler}>
              Cancel
            </button>
            <button
              className="delete__modalconfirm"
              onClick={confirmDeleteHandler}
            >
              Confirm
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

import "./footer.css";
import footerCass from "../../images/footerCass.png";
export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__first">
        <img src={footerCass} alt="cass" style={{ width: "100px" }} />
        <div className="text-dark mt-3">
          2730 Peralta Street Oakland CA 94607 | 510.893.6476
        </div>
      </div>
      <div className="footer__second ">
        <div className="footer__second__company">
          <p>
            <b>company</b>
          </p>
          <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
            <li>About</li>
            <li>Community</li>
            <li>Media</li>
            <li>Contact Us</li>
            <li>Shop</li>
          </ul>
        </div>
        <div className="footer__second__services">
          <p>
            <b>services</b>
          </p>
          <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
            <li>Services</li>
            <li>Recycling</li>
            <li>Manufacturing</li>
            <li>Transportation</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

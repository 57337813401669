import "./adminSignup.css";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { SignupForm } from "../signupForm/signupForm";
import { useEffect, useState } from "react";
import { UsersTable } from "../usersTable/usersTable";
import { useSelector } from "react-redux";
import useFetch from "../../utils/useFetch";

export const AdminSignup = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [users, setUsers] = useState();
  const { data, loading, error, setData } = useFetch(
    "https://videoapp.customalloy.com/",
    true
  );

  if (loading) return <div>loading</div>;
  if (error) return <div>error</div>;
  return (
    <div className="admin__signup">
      <Button onClick={handleOpen} variant="contained">
        Sign Up a user
      </Button>
      <UsersTable users={data} />
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ width: "50vw" }}
        >
          <SignupForm
            handleClose={handleClose}
            setData={setData}
            users={users}
          />
        </Modal>
      </div>
    </div>
  );
};

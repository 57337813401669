import "./dahsboardTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { Pagination } from "../pagination/pagination";
import { DeleteModal } from "../deleteModal/deleteModal";

const LOCAL_SERVER = "http://localhost:8000";
const SERVER = "https://videoapp.customalloy.com";
export const DahsboardTable = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [videoPerPage, setUsersPerPage] = useState(5);

  const lastVideoIndex = currentPage * videoPerPage;
  const firstVideoIndex = lastVideoIndex - videoPerPage;

  const [videos, setVideos] = useState(data);

  const currentVideos =
    videos && videos?.slice(firstVideoIndex, lastVideoIndex);
  const copyLinkHandler = (video) => {
    navigator?.clipboard?.writeText(`${SERVER}/${video.videoName}`);
  };

  return (
    <div style={{ background: "blue", height: "auto", marginBottom: "5rem" }}>
      <TableContainer
        className="dashboardtable"
        style={{
          width: "80vw",

          tableLayout: "fixed",
        }}
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" align="center">
          <TableHead>
            <TableRow className="bg-dark">
              <TableCell className="text-white" align="center">
                Video Name
              </TableCell>
              <TableCell className="text-white" align="center">
                Upload Date
              </TableCell>
              <TableCell className="text-white" align="center">
                Video Link
              </TableCell>
              <TableCell className="text-white" align="center">
                Copy Video Link
              </TableCell>
              <TableCell className="text-white" align="center">
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentVideos &&
              currentVideos?.map((video, index) => (
                <TableRow
                  key={video._id}
                  // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {video.caption}
                  </TableCell>
                  <TableCell align="center">
                    {new Date(video.createdAt).toLocaleDateString("en-gb")}
                  </TableCell>
                  <TableCell align="center">
                    {video.videoName ? (
                      <>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://videoapp.customalloy.com/${video.videoName}`}
                        >{`https://videoapp.customalloy.com/${video.videoName}`}</a>
                      </>
                    ) : (
                      <>null</>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <ContentCopyIcon
                      role="button"
                      onClick={() => copyLinkHandler(video, index)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteModal
                      videoId={video._id}
                      videoName={video.videoName}
                      setVideos={setVideos}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <div style={{ height: "50px" }}>
          {" "}
          <Pagination
            totalPosts={videos?.length}
            postsPerPage={videoPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </TableContainer>
    </div>
  );
};

import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { Upload } from "./pages/upload";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "./pages/login";
import { ProtectedRoutes } from "./utils/protectedRoutes";
import { Dashboard } from "./pages/dashboard";
import { ForgotPassword } from "./pages/forgotPassword";
import { ChangePassowrd } from "./components/changePassword/changePassword";
import { Deleted } from "./pages/deleted";

const App = () => {
  return (
    <>
      <div className="app">
        <BrowserRouter>
          <Routes>
            {" "}
            <Route
              path="/upload"
              element={
                <ProtectedRoutes>
                  <Upload />
                </ProtectedRoutes>
              }
            />
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoutes>
                  <Dashboard />
                </ProtectedRoutes>
              }
            />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="reset/:token" element={<ChangePassowrd />} />
            <Route path="/deleted" element={<Deleted />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;

import axios from "axios";
import { useState } from "react";
import "./generateLink.css";

const LOCAL_URL = "http://localhost:8000";
const LOCAL_SERVER = "http://localhost:8000";
const SERVER = "https://videoapp.customalloy.com";
const URL = "https://videoapp.customalloy.com";
export const GenerateLink = ({
  data,
  setfile,
  setLinkBtn,
  caption,
  setMainVisible,
  setVideoName,
  setToastify,
}) => {
  const [videoId, setVideoId] = useState();
  const [videoLink, setVideoLink] = useState("");
  const [generateVisible, setGenerateVisible] = useState(true);
  const sharableLinkHandler = async (id, e) => {
    e.preventDefault();
    setVideoId(id);
    const formData = new FormData();

    formData.append("id", caption);
    await axios
      .post(`${URL}/link`, { videoId: id })
      .then((response) => {
        // console.log(response.data.data);
        setVideoLink(response.data.data);
        setGenerateVisible(false);
        setLinkBtn(true);
      })
      .catch((error) => console.log(error));
  };
  const copyLink = async (videoLink, event) => {
    event.preventDefault();
    navigator?.clipboard?.writeText(`${SERVER}/${videoLink.videoName}`);
    setfile();
    setVideoName("No Video Selected");
    setToastify(true);
  };
  const uploadNewVideoHandler = () => {
    setMainVisible(true);
  };

  return (
    <>
      <div className="generateLink">
        {data &&
          data?.map((vid) => (
            <div className="link__div  shadow shadow-lg" key={vid._id}>
              <div className="maindiv__card__form">
                <div className="maindiv__Card__form__div mt-5">
                  <input
                    placeholder="Name Your Video"
                    type="text"
                    defaultValue={vid.caption}
                    className="maindiv__Card__form__caption"
                  />
                </div>
                {generateVisible ? (
                  <>
                    {" "}
                    <div className="maindiv__card__form__file">
                      <label
                        htmlFor="video-upload"
                        className="custom-upload-btn"
                      >
                        <b onClick={(e) => sharableLinkHandler(vid._id, e)}>
                          Generate Link{" "}
                        </b>
                      </label>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="d-flex flex-column  ">
                  {videoLink ? (
                    <>
                      {" "}
                      <button
                        className="maindiv__card__form__btn"
                        type="submit"
                      >
                        <b onClick={(event) => copyLink(videoLink, event)}>
                          Copy Link
                        </b>
                      </button>
                      <label
                        htmlFor="video-upload"
                        className="custom-upload-btn mt-3  "
                      >
                        <b onClick={uploadNewVideoHandler}>Upload New Video</b>
                      </label>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import axios from "axios";

const LOCAL_URL = "http://localhost:8000";
const URL = "https://videoapp.customalloy.com";

const initialState = {
  loading: false,
  userToken: "",
  token: false,
  role: "",
  error: false,
  errorMessage: {},
  success: false,
};

export const loginUser = createAsyncThunk("login", async (body) => {
  const response = await axios.post(
    `${URL}/login`,
    { email: body.email, password: body.password },
    {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    }
  );
  return await response;
});
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutUser: (state, action) => {
      state.userToken = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      let decoded = jwt_decode(action.payload.data.token);
      state.userToken = action.payload.data.token;
      state.loading = false;
      state.error = false;
      state.token = true;
      state.role = decoded.role;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.error = true;
      state.loading = false;
      state.token = false;
      state.errorMessage = action;
    });
  },
});
export const { logoutUser } = authSlice.actions;
export default authSlice.reducer;

import { TextField } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EmailConfirmation } from "../components/emailConfirmation/emailConfirmation";
import { Background } from "../components/UI/background/background";
import "./forgotPassword.css";
const LOCAL_SERVER = "http://localhost:8000";
const SERVER = "https://videoapp.customalloy.com";
export const ForgotPassword = () => {
  const { message } = useSelector((state) => state.info);
  const [email, setEmail] = useState("");
  const [info, setInfo] = useState("");
  const [success, setSuccess] = useState(false);
  const [isdisabled, setIsDisabled] = useState(true);
  const sendForgotEmail = (e) => {
    e.preventDefault();

    axios
      .post(`${SERVER}/forgotconfirmation`, { email: email })
      .then((response) => {
        if (response.status === 201) {
          setSuccess(true);
          setInfo("");
        }
      })
      .catch((error) => {
        setInfo("The email address does not exist in our system.");
      });
  };
  const emailHandler = (e) => {
    if (e.target.value.length > 0) {
      setEmail(e.target.value);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <Background>
      {success ? (
        <>
          <EmailConfirmation />
        </>
      ) : (
        <>
          <div className="forgotpassword__div">
            <div className="forgotpassword__inputgroup">
              <label className="forgotpassword__label">
                Type your Email to reset your password
              </label>
              {!message ? (
                <></>
              ) : (
                <p style={{ textAlign: "center", color: "red" }}>{message}</p>
              )}
              <TextField
                className="forgotpassword__input"
                // id="outlined-basic"
                label="Type your email"
                variant="outlined"
                type="text"
                onChange={emailHandler}
              />
            </div>

            <button
              className="forgotpassword__btn"
              onClick={sendForgotEmail}
              disabled={isdisabled}
            >
              Send Email
            </button>
            {!info ? (
              <></>
            ) : (
              <p style={{ marginTop: "5px", color: "red" }}>{info}</p>
            )}
          </div>
        </>
      )}
    </Background>
  );
};

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/authSlice";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import infoSlice from "./redux/infoSlice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    info: infoSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;

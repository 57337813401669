import { Grid, Paper, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addInfoMessage } from "../../redux/infoSlice";
import { ClosePage, Redirect } from "../closePage/redirect";
import { Background } from "../UI/background/background";
import "./changePassword.css";
const LOCAL_SERVER = "http://localhost:8000";
const SERVER = "https://videoapp.customalloy.com";
export const ChangePassowrd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const expirationdate = jwt_decode(token).exp;
    if (expirationdate * 1000 < Date.now()) {
      dispatch(
        addInfoMessage(
          "The link you clicked has expired. Please enter your email to send a new link."
        )
      );
      navigate("/forgotpassword");
    }
  }, [token, navigate, dispatch]);
  const resetPasswordHandler = (e) => {
    if (password.password === password.confirmPassword) {
      axios
        .post(
          `${SERVER}/reset/${token}`,
          { password: password.password },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          if ((response.status = 201)) {
            setSuccess(true);
          } else {
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            setErrorMessage("error occured please try again");
          }
        });
    } else {
      setErrorMessage(
        "Password Mismatch: The passwords you've entered do not match. Please re-enter to ensure they are the same."
      );
    }
  };
  const typePasswordHandler = (input, value) => {
    setPassword((prevPassword) => {
      return { ...prevPassword, [input]: value };
    });
    setErrorMessage("");
  };
  return (
    <>
      {!success ? (
        <Background>
          <div className="changepassword">
            <Paper className="changepassword__paper">
              <>
                <div className="changepassword__header">Password Reset</div>
                <Grid style={{ padding: "20px" }} item xs={12} sm={10}>
                  <TextField
                    required
                    name="password"
                    type="password"
                    label="New Password"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) =>
                      typePasswordHandler("password", e.target.value)
                    }
                  />
                </Grid>
                <Grid style={{ padding: "20px" }} item xs={12} sm={10}>
                  <TextField
                    required
                    name="title"
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    size="small"
                    variant="outlined"
                    onChange={(e) =>
                      typePasswordHandler("confirmPassword", e.target.value)
                    }
                  />
                </Grid>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Button variant="contained" onClick={resetPasswordHandler}>
                    Save new Password
                  </Button>
                </div>
                <div>
                  {" "}
                  {errorMessage ? (
                    <div
                      style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "small",
                        textAlign: "center",
                        margin: "5px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            </Paper>
          </div>
        </Background>
      ) : (
        <Redirect />
      )}
    </>
  );
};

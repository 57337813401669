import axios from "axios";
import { useState } from "react";
import { Footer } from "../components/footer/footer";
import { Loader } from "../components/loader/loader";
import cassLogo from "../images/cassLogo.png";
import { ToastContainer, toast } from "react-toastify";
import { GenerateLink } from "../components/generateLink/generateLink";
import "./upload.css";
import { Header } from "../components/header/header";
import { useSelector } from "react-redux";
const URL = "https://videoapp.customalloy.com";
const LOCAL_URL = "http://localhost:8000";

export const Upload = () => {
  const [file, setfile] = useState();
  const [caption, setCaption] = useState("");
  const [data, setData] = useState([]);
  const { userToken } = useSelector((state) => state.auth);
  const [loadingPercentage, setLoadingPercentage] = useState();
  const [loading, setLoading] = useState(false);
  const [linkbtn, setLinkBtn] = useState(false);
  const [mainVisisble, setMainVisible] = useState(true);
  const [videoName, setVideoName] = useState("No Video Selected");
  const [tostify, setToastify] = useState(false);
  // console.log(userToken);
  let array = [];
  const onFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("caption", caption);
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setLoadingPercentage(percentCompleted);
      },
    };
    axios
      .post(`${URL}/upload`, formData, config)
      .then((response) => {
        array.push(response.data.data);
        // console.log(array);
        setData(array);
        setLoading(false);
        setMainVisible(false);
      })

      .catch((error) => {});
  };

  const onChange = (e) => {
    setVideoName(e.target.files[0].name);
    setfile(e.target.files);
  };
  const captionHandler = (e) => {
    setCaption(e.target.value);
  };

  const triggerNotify = () => {
    toast("You succefully copied the url in your clipboard", {
      toastId: 1,
    });
    setToastify(false);
  };
  tostify === true ? triggerNotify() : <></>;
  return (
    <>
      {/* <div className="announcment">
        <div> In our new update you can upload </div>
        <div>
          <span className="announcment__span1"> videos,</span>{" "}
          <span className="announcment__span2"> images</span>{" "}
          <span className="announcment__span3"> and PDFs too! </span>{" "}
        </div>
      </div> */}
      <Header />

      <>
        {loading === true ? (
          <Loader loadingPercentage={loadingPercentage} />
        ) : (
          <>
            <div className="quotediv ">
              <div className="quotediv__imgdiv">
                <img
                  src={cassLogo}
                  alt="cass logo"
                  style={{ height: "18vh" }}
                />
              </div>
              <div className="quotediv__text">
                <b>STREAM CASS</b>
              </div>
              <div className="quotediv__slogan mb-3">
                Empowering Business Communication With <br /> Video Streaming.
              </div>
            </div>
            {mainVisisble ? (
              <div className="maindiv">
                <div className="maindiv__card  shadow-lg">
                  <form
                    className="maindiv__card__form"
                    onSubmit={(e) => onFormSubmit(e)}
                  >
                    <div className="maindiv__Card__form__div mt-5">
                      <input
                        placeholder="Name Your Video"
                        type="text"
                        name="caption"
                        className="maindiv__Card__form__caption"
                        onChange={captionHandler}
                        required={true}
                      />
                    </div>
                    <div className="maindiv__card__form__file">
                      <label
                        htmlFor="video-upload"
                        className="custom-upload-btn"
                      >
                        <b>Choose Video</b>
                      </label>
                      <input
                        type="file"
                        id="video-upload"
                        accept="video/* , image/* , .pdf"
                        onChange={(e) => onChange(e)}
                        required={true}
                        multiple={false}
                      />
                      <span id="file-name">
                        {videoName.length > 20 ? (
                          videoName.substring(0, 15) + "..."
                        ) : (
                          <b>{videoName}</b>
                        )}
                      </span>
                    </div>
                    <div>
                      {" "}
                      <button
                        className="maindiv__card__form__btn"
                        type="submit"
                      >
                        <b>Upload your video</b>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <>
                <GenerateLink
                  data={data}
                  linkbtn={linkbtn}
                  setLinkBtn={setLinkBtn}
                  caption={caption}
                  setMainVisible={setMainVisible}
                  setfile={setfile}
                  setVideoName={setVideoName}
                  setToastify={setToastify}
                />
              </>
            )}
          </>
        )}
      </>
      <ToastContainer />

      <Footer />
    </>
  );
};

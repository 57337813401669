import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  message: "",
};
export const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    addInfoMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});
export const { addInfoMessage } = infoSlice.actions;
export default infoSlice.reducer;
